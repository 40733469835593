import { Web3Provider } from '@ethersproject/providers';
import EthereumWalletConnectProvider from '@walletconnect/ethereum-provider';
import Web3WalletConnectProvider from '@walletconnect/web3-provider';
import { mixins } from 'vue-class-component';
import { EthereumProvider } from '@manifoldxyz/manifold-sdk';
import { AbstractProvider } from '@/common/constants';
import WalletMixin from '@/mixins/wallet';

export default class MultiWalletMixin extends mixins(WalletMixin) {
  async connectWalletConnect(): Promise<void> {
    try {
      // set false by the addressChanged handler or disconnect call in catch
      this.isLoading = true;
      await this._connectWithWalletConnect();
    } catch (error) {
      await this._disconnect(true);
    }
  }

  async _connectWithWalletConnect(): Promise<void> {
    // if they did not provider a specific wallet provider, use the fallback
    let providerURI = this.fallbackProvider;

    if (providerURI) {
      // modify the provider URI syntax so that it always works with WalletConnect
      const infuraMatch = providerURI.match(
        /^(wss:\/\/)(.*\.infura.io)(\/ws)(\/v[0-9]+\/[0-9a-f]+)$/
      );
      if (infuraMatch) {
        providerURI = `https://${infuraMatch[2]}${infuraMatch[4]}`;
      } else {
        providerURI = providerURI.replace('wss://', 'https://');
        providerURI = providerURI.replace('ws://', 'http://');
      }
    }

    // create the separate WC provider and use it as the _signingProvider in EthereumProvider
    const wcProvider =
      providerURI && this.network
        ? new Web3WalletConnectProvider({
            rpc: { [this.network.valueOf()]: providerURI }
          })
        : new EthereumWalletConnectProvider({ chainId: this.network });

    // disconnect if they just close modal and select nothing for WC
    wcProvider.connector.on('modal_closed', () => {
      this._disconnect(true);
      this.isLoading = false;
    });
    await wcProvider.enable();
    const wcSigningProvider = new Web3Provider(wcProvider);
    await EthereumProvider.setSigningProvider(wcSigningProvider);
    localStorage.setItem('connectMethod', 'walletConnect');
  }

  async _automaticallyReconnect(): Promise<void> {
    if (this.autoReconnect) {
      if (localStorage.getItem('connectedAddress')) {
        // make sure to reconnect using the correct connection method
        if (localStorage.getItem('connectMethod') === 'walletConnect') {
          await this._connectWithWalletConnect();
        } else {
          await this._connectWithEthereumProvider(true);
        }
      }
    }
  }

  /**
   * Disconnects from web3
   */
  async _disconnect(skipProviderDisconnect = false): Promise<void> {
    if (this.walletConnected) {
      // remove local storage
      localStorage.removeItem('connectMethod');

      const signingProvider = EthereumProvider.provider(true) as AbstractProvider;
      // if the signing provider is a WalletConnect provider
      if (
        !skipProviderDisconnect &&
        signingProvider &&
        (signingProvider.provider instanceof Web3WalletConnectProvider ||
          signingProvider.provider instanceof EthereumWalletConnectProvider)
      ) {
        try {
          await signingProvider.provider.disconnect();
        } catch (error) {
          // eat the error as it's not critical if this step goes awry
          console.warn(error);
        }
        await this._disconnectBase();
      } else {
        // _disconnectBase and skipProviderDisconnected so that we can
        // do it ourselves while taking into account this.strictAuth
        await this._disconnectBase(skipProviderDisconnect);
      }
    }
  }
}
