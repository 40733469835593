import { OAuthGrantType } from '@manifoldxyz/manifold-sdk';

const backendURL =
  'https://kygy4d4nc3.execute-api.us-east-1.amazonaws.com/prod/app/public?redirectURI=';

export interface AppDetails {
  clientId: string;
  app: string;
  redirectUri: string;
  grantType: OAuthGrantType;
}

export const getDetails = async (siteURI: string): Promise<AppDetails> => {
  const appDetails = await fetch(`${backendURL}${siteURI}`);
  if (appDetails.status === 200) return await appDetails.json();
  let errorMessage;
  try {
    const error = await appDetails.json();
    errorMessage = error.message || error.error;
  } catch (e) {
    // Could not decode error response
    console.error('Auto-detect error: ', e);
  }
  throw new Error(errorMessage || 'Service unavailable');
};
