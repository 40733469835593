import { inject, provide } from 'vue';
import { mixins, Options, Vue } from 'vue-class-component';
import { EthereumNetwork, OAuthGrantType } from '@manifoldxyz/manifold-sdk';

/*
 * Adding a new prop? You'll want to declare in two places:
 *   1. MConnectOptionsProps with type and default
 *   2. MConnectProps (theres an obvious pattern)
 *
 * Optionally, enforce any new prop-dependency rules
 * in the created() hook of MConnectProvidePropsMixin.
 */
export const MConnectOptionsProps = {
  showBalance: {
    type: Boolean,
    default: true
  },
  connectWalletImage: {
    type: String,
    default: ''
  },
  showChain: {
    type: Boolean,
    default: true
  },
  avatar: {
    type: String,
    default: ''
  },
  multi: {
    type: Boolean,
    default: false
  },
  minimal: {
    type: Boolean,
    default: false
  },
  fallbackProvider: {
    type: String,
    default: ''
  },
  network: {
    type: Number,
    default: EthereumNetwork.MAINNET
  },
  parentFrameUrl: {
    type: String,
    default: ''
  },
  autoReconnect: {
    type: Boolean,
    default: true
  },
  overrideConnectText: {
    type: String,
    default: ''
  },
  strictAuth: {
    type: Boolean,
    default: true
  },
  delayAuth: {
    type: Boolean,
    default: false
  },
  clientId: {
    type: String,
    default: ''
  },
  appName: {
    type: String,
    default: ''
  },
  grantType: {
    type: String,
    default: OAuthGrantType.SIGNATURE
  },
  detectApp: {
    type: Boolean,
    default: false
  },
  message: {
    type: String,
    default: ''
  }
};
export type MConnectOptionsPropsType = keyof typeof MConnectOptionsProps;

// Defined once and extended so that the inject() and provide() calls made by
// the two mixins in this file define all of the same things.
export class MConnectProps extends Vue {
  showBalance = MConnectOptionsProps.showBalance.default;
  connectWalletImage = MConnectOptionsProps.connectWalletImage.default;
  showChain = MConnectOptionsProps.showChain.default;
  avatar = MConnectOptionsProps.avatar.default;
  multi = MConnectOptionsProps.multi.default;
  minimal = MConnectOptionsProps.minimal.default;
  fallbackProvider = MConnectOptionsProps.fallbackProvider.default;
  network: EthereumNetwork = MConnectOptionsProps.network.default;
  parentFrameUrl = MConnectOptionsProps.parentFrameUrl.default;
  autoReconnect = MConnectOptionsProps.autoReconnect.default;
  overrideConnectText = MConnectOptionsProps.overrideConnectText.default;
  strictAuth = MConnectOptionsProps.strictAuth.default;
  delayAuth = MConnectOptionsProps.delayAuth.default;
  clientId = MConnectOptionsProps.clientId.default;
  appName = MConnectOptionsProps.appName.default;
  grantType = MConnectOptionsProps.grantType.default;
  detectApp = MConnectOptionsProps.detectApp.default;
  message = MConnectOptionsProps.message.default;
}

// Used by WalletMixin to inject() back in all parent-provided props as variables
// Reference: https://vuejs.org/guide/components/provide-inject.html
export class MConnectInjectPropsMixin extends mixins(MConnectProps) {
  created(): void {
    // get all property definitions declared in MConnectProvidePropsMixin
    const keys = Object.keys(new MConnectProps({}));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      (this as any)[key] = inject(key); // eslint-disable-line
    }
  }
}

// Used by MConnect to provide() all its props to children components
// Reference: https://vuejs.org/guide/components/provide-inject.html
@Options({
  props: MConnectOptionsProps
})
export class MConnectProvidePropsMixin extends mixins(MConnectProps) {
  /*
   * Use this created hook to enforce any prop-dependency rules.
   */
  created(): void {
    if (this.multi && this.minimal) {
      throw Error('Props multi and minimal are mutually exclusive');
    }

    if ((this.appName || this.clientId) && !(this.appName && this.clientId)) {
      throw Error('Props app-name and client-id must be provided together');
    }

    if (this.detectApp && (this.appName || this.clientId)) {
      throw Error('Props app-name and client-id cannot be provided if detect-app is true');
    }

    if (this.fallbackProvider && !this.network) {
      throw Error('Prop network must be provided if fallbackProvider is provided');
    }

    // define all properties for children who use the MConnectInjectPropsMixin
    const keys = Object.keys(new MConnectProps({}));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      provide(key, (this as any)[key]); // eslint-disable-line
    }
  }
}
