import { mixins } from 'vue-class-component';
import ManifoldDataClient from '@manifoldxyz/manifold-data-client';
import { EthereumProvider } from '@manifoldxyz/manifold-sdk';
import { AppDetails, getDetails } from '@/api/auto-detect';
import { AUTHENTICATED, UNAUTHENTICATED } from '@/common/constants';
import web3TransactionErrorHandling, {
  transactionError,
  TransactionErrors
} from '@/common/web3TransactionErrorHandling';
import WalletMixin from '@/mixins/wallet';

export default class OAuthWalletMixin extends mixins(WalletMixin) {
  oauthToken: string | null | undefined = null;
  signatureAddress: string | null | undefined = null;
  detectedApp: AppDetails | undefined = undefined;

  /**
   * Disconnects from web3 and deletes our OAuth cookie for the JSON API.
   */
  async disconnectWallet(): Promise<void> {
    await this._disconnect();
  }

  /**
   * Disconnects from web3
   */
  async _disconnect(skipProviderDisconnect = false): Promise<void> {
    await this._disconnectOAuth(skipProviderDisconnect);
  }

  /**
   * Disconnect OAuth
   */
  async _disconnectOAuth(skipProviderDisconnect = false): Promise<void> {
    if (this.walletConnected) {
      const walletAddressFull = this.walletAddressFull;
      const oauthToken = this.oauthToken;

      // _disconnectBase and skipProviderDisconnected so that we can
      // do it ourselves while taking into account this.strictAuth
      await this._disconnectBase(true);

      // Previously had a wallet and was authed, need to reset
      if (oauthToken && walletAddressFull) {
        this.oauthToken = undefined;
        window.manifold = {
          isAuthenticated: false,
          connectedAddress: ''
        };
        // so that client can also listen for auth state change
        window.dispatchEvent(
          new CustomEvent(UNAUTHENTICATED, {
            detail: {
              address: this.walletAddressFull
            }
          })
        );
      }
      if (!skipProviderDisconnect) {
        // this will trigger onAddressChanged which handles state etc
        await EthereumProvider.disconnect(this.strictAuth);
      }
    }
  }

  /**
   * Authentication helper
   *
   * First it updates all chainInfo.
   * Then it updates everything related to the wallet adddress and ens name
   * if possible. Finally it stores the wallet address as "connectAddress"
   * inside of local storage. If ther ewas an issue or the address is now
   * undefined/null, we clear all wallet related vlaues and clear localStorage
   * of the "connectedAddress" itme.
   */
  async _authenticate(force = false): Promise<void> {
    this.badConfiguration = null;

    // switching addresses without an oauth causes delayAuth to be false
    const continueToDelayAuth = !this.walletAddressFull && this.delayAuth && !this.oauthToken;

    this.updateChainInfo();
    const address = EthereumProvider.selectedAddress();
    const ens = EthereumProvider.selectedENSName();

    // Only change if address has changed OR it has been zero'd out
    if (
      force ||
      address !== this.walletAddressFull ||
      ens !== this.walletENS ||
      address === undefined
    ) {
      // Reset current state via disconnect
      await this._disconnect(true);

      this.walletAddressFull = address;
      this.walletENS = ens;

      if (address) {
        try {
          this.signatureAddress = this.walletAddressFull;

          let clientId = this.clientId;
          let appName = this.appName;
          let grantType = this.grantType;

          // check for bad configuration
          if ((!clientId || !appName) && !this.detectedApp) {
            const currentPage = window.location.origin;
            try {
              this.detectedApp = await getDetails(currentPage);
            } catch (e) {
              const error = e as { message?: string };
              this.badConfiguration = error.message || 'Config Error';
              throw new Error(this.badConfiguration);
            }
          }

          if (this.detectedApp) {
            clientId = this.detectedApp.clientId;
            appName = this.detectedApp.app;
            grantType = this.detectedApp.grantType;
          }

          // check if they want an oauth token and data client
          if (clientId && appName) {
            this.oauthToken = await EthereumProvider.getOAuth({
              grantType: grantType,
              appName: appName,
              clientId: clientId,
              strictAuth: this.strictAuth,
              delayAuth: continueToDelayAuth,
              message: this.message ? this.message : ''
            });

            // TODO: move this type of stuff to connect-sdk when it exists
            if (this.oauthToken) {
              // so that client can query the current auth state
              window.manifold = {
                isAuthenticated: true,
                connectedAddress: this.signatureAddress
              };

              // so that client can also listen for auth state change and use data client
              window.dispatchEvent(
                new CustomEvent(AUTHENTICATED, {
                  detail: {
                    address: this.signatureAddress,
                    accessToken: this.oauthToken,
                    client: new ManifoldDataClient({
                      token: this.oauthToken || '',
                      network: this.network
                    })
                  }
                })
              );
            }
          }

          // update UX variables
          const addressLength = address.length;
          const retval =
            address.slice(0, 6) + '...' + address.slice(addressLength - 4, addressLength);
          this.walletAddressShort = retval;
          this.walletConnected = true;
          localStorage.setItem('connectedAddress', address);
        } catch (error) {
          if (!this.badConfiguration) {
            const transactionErrors = web3TransactionErrorHandling(error as transactionError);
            switch (transactionErrors) {
              case TransactionErrors.REJECTED: {
                // Need walletConnected to be true to disconnect properly
                this.walletConnected = true;
                await this._disconnect(false);
                this.oauthToken = undefined;
                break;
              }
              case TransactionErrors.LEDGER_ERROR: {
                // Need walletConnected to be true to disconnect properly
                this.walletConnected = true;
                await this._disconnect(false);
                this.oauthToken = undefined;
                break;
              }
              case TransactionErrors.PENDING: {
                alert(`Please open ${this.browserWalletName} Wallet to continue.`);
                break;
              }
              default: {
                // Need walletConnected to be true to disconnect properly
                this.walletConnected = true;
                await this._disconnect(false);
                this.oauthToken = undefined;
                // removeing the alert for now till we figure out why the error message for transaction reject is weird
                // alert('There was an issue with that wallet connection');
                break;
              }
            }
          }
        }
      } else {
        this._disconnect(true);
      }

      this.isLoading = false;
    }
  }
}
