
import { mixins } from 'vue-class-component';
import OAuthWalletMixin from '@/mixins/oAuthWallet';

export default class OAuthConnect extends mixins(OAuthWalletMixin) {
  showDisconnectMenu = false;

  toggleDisconnectMenu(): void {
    if (!this.wrongChain) {
      this.showDisconnectMenu = !this.showDisconnectMenu;
    }
  }

  closeDisconnectMenu(): void {
    this.showDisconnectMenu = false;
  }

  async disconnectAndClose(): Promise<void> {
    this.closeDisconnectMenu();
    await this.disconnectWallet();
  }
}
