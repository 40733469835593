import { createApp, h, render, App, VNode, Component } from 'vue';

type RenderPrefs = {
  component: Component;
  props: any;
  el: Element;
}

/*
 * Returns a functional hook you can call to destroy the vnode
 * Reference: https://vuejs.org/guide/extras/render-function.html#creating-vnodes
 */
export const renderComponentWithVNode = (prefs: RenderPrefs) => {
  let vnode : VNode | undefined = h(prefs.component, prefs.props);
  render(vnode, prefs.el);

  return () => {
    // destroy vnode
    render(null, prefs.el);
    vnode = undefined;
  };
};

/*
 * Returns a functional hook you can call to destroy the component-app
 */
export const renderComponentWithApp = (prefs: RenderPrefs) => {
  let app : App | undefined = createApp(prefs.component, prefs.props);
  app.mount(prefs.el);

  return () => {
    // destroy app with just one component in it
    app?.unmount();
    app = undefined;
  };
};
